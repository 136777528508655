import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Select from "react-select";
import {useTranslation} from 'next-i18next';
import "../../../node_modules/slick-carousel/slick/slick.css";
import Link from "next/link";
import Image from "next/legacy/image";

import {s3Link} from "../../../config/ethereum-config";
// API helper
// styles
import styles from "./TopSellers.module.scss";

//react-shimmer-effects
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Navigation} from "swiper";
// profile
import profileImg from '../../../public/collection-img.png'
import verified from '../../../public/tick.png'
import sellerTwitterIcon from '../../../public/home/sellerTwitterIcon.svg'
import sellerPortfolioIcon from '../../../public/home/sellerPortfolio.svg'
import sellerInstagramIcon from '../../../public/home/sellerInstagramIcon.svg'


const TopSellers = (props) => {

    const{ t } = useTranslation('common');
    const [sellerList, setSellerList] = useState(props.sellerList);
    const [cAddress] = useState(false);
    const [current] = useState(0);
    const [pValue, setPValue] = useState({
        value: 'verified', label: t('common:verified')
    });
    const [collectionOptions] = [
        { value: 'verified', label: t('common:verified') },
        { value: 'newest', label: t('common:newest') }
    ];

    useEffect(() => {
        const tempSellerList = props.sellerList.filter(item => item.owner.isVerified);
        const sortedList = tempSellerList.sort((a,b)=> a.owner.ranking - b.owner.ranking)
        setSellerList(sortedList);
    },[])

    useEffect(()=>{
        (async () => await getAllSeller())();
    },[pValue])


    const getAllSeller = async () => {
        if (pValue.value === "verified") {
            const tempSellerList = props.sellerList.filter(item => item.owner.isVerified);
            const sortedList = tempSellerList.sort((a,b)=> a.owner.ranking - b.owner.ranking)
            setSellerList(sortedList);
        }else{
            setSellerList(props.sellerList);
        }
    }

    const handleOptions = (e) => {
        setPValue(e);
    }

    return (
            <div className={`section ${styles.top_sellers}`}>
                <Container>
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                        <h2 className={`h3 mb-0 d-flex align-items-center ${styles.h3}`}>
                            {pValue.value === "verified" ?
                                <>
                                    <span>{t('common:verified_seller')}</span>
                                    <Image src={verified} alt={t('common:verified_seller')}
                                           width="26" height="26" layout='responsive' />
                                </>
                                : t('common:popular_seller')}
                        </h2>
                        <div className={`${styles.selectMenu} selectMenu`}>
                            <Select
                                value={pValue}
                                onChange={handleOptions}
                                options={collectionOptions}
                                classNamePrefix="select__Inner"
                                placeholder={t('common:select')}
                                isSearchable={false}
                                instanceId="clcMenu"
                            />
                        </div>
                    </div>
                    <div className={styles.topSellers}>
                        { sellerList?.length > 0 ?
                                <>
                                    <Swiper
                                        dir={props.currLocale === "ar" ? "rtl" : "ltr"}
                                        navigation={{
                                            nextEl: '.button-next',
                                            prevEl: '.button-prev',
                                        }}
                                        modules={[Navigation]}
                                        slidesPerView={4}
                                        slidesPerGroup={4}
                                        freeMode={true}
                                        mousewheel={true}
                                        grabCursor={true}
                                        speed={400}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 2,
                                                slidesPerGroup: 2,
                                                spaceBetween: 16
                                            },
                                            576: {
                                                slidesPerView: 2,
                                                slidesPerGroup: 2,
                                                spaceBetween: 24
                                            },
                                            768: {
                                                slidesPerView: 3,
                                                slidesPerGroup: 3,
                                                spaceBetween: 24
                                            },
                                            1200: {
                                                slidesPerView: 4,
                                                spaceBetween: 30
                                            },
                                        }}
                                    >
                                        {sellerList?.map((item, idx) => {
                                            if (item.owner.displayName && item.owner.profilePhoto) {
                                                return (
                                                    <SwiperSlide key={idx}>
                                                        <Card className={styles.card}>
                                                            <Link href={"/nft/profiles/" + (item.owner.customUrl ? item.owner.customUrl : item.owner.walletAddress)}>
                                                                <div className={styles.sellerImg}>
                                                                        <Image src={item.owner.profilePhoto ? s3Link + "profileimage/" + item.owner.profilePhoto : profileImg}
                                                                            alt="team_1"
                                                                            width={256}
                                                                            height={260}
                                                                            layout="responsive"
                                                                        />
                                                                    </div>
                                                                <Card.Title className={`d-flex align-items-center ${styles.name}`}>
                                                                        <span>
                                                                            {item.owner.displayName ? item.owner.displayName :
                                                                                <>
                                                                                    <div className={styles.user__number}>
                                                                                        <span>
                                                                                            {cAddress && current === idx && item.owner.walletAddress ? "Copied !" : (item.owner.walletAddress).substring(1, 8) + "..." + (item.owner.walletAddress).slice(-4)}
                                                                                        </span>
                                                                                    </div>
                                                                                </>
                                                                            }</span>
                                                                        {item.owner.isVerified && <Image src={verified}
                                                                                                        width={16}
                                                                                                        height={16}
                                                                                                        alt="verification"
                                                                                                        layout='responsive' />
                                                                        }
                                                                    </Card.Title>
                                                            </Link>
                                                            <Card.Body className={styles.cardBody}>
                                                                <div className={`d-flex ${styles.sellerDetails}`}>

                                                                    {item.owner.twitterURL &&
                                                                        <Link href={item.owner.twitterURL}
                                                                              target="_blank"
                                                                              className={styles.socialLink}
                                                                              rel="nofollow, noopener"
                                                                              aria-label='twitter'>
                                                                            <Image src={sellerTwitterIcon}
                                                                                   width={18}
                                                                                   height={16} />
                                                                        </Link>
                                                                    }

                                                                    {item.owner.portfolioURL &&
                                                                        <Link href={item.owner.portfolioURL.trim()}
                                                                              target="_blank"
                                                                              className={styles.socialLink}
                                                                              rel="nofollow, noopener"
                                                                              aria-label='portfolio'>
                                                                            <Image src={sellerPortfolioIcon}
                                                                                   width={18}
                                                                                   height={16} />
                                                                        </Link>
                                                                    }

                                                                    {item.owner.instagramURL &&
                                                                        <Link href={item.owner.instagramURL}
                                                                              target="_blank"
                                                                              className={styles.socialLink}
                                                                              rel="nofollow, noopener"
                                                                              aria-label='instagram'>
                                                                            <Image src={sellerInstagramIcon}
                                                                                   width={18}
                                                                                   height={16} />
                                                                        </Link>
                                                                    }

                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </SwiperSlide>
                                                )
                                            }
                                        })}
                                    </Swiper>
                                    <div className="button-prev"></div>
                                    <div className="button-next"></div>
                                </>
                                : <p>{t('common:no_sellers_found')}</p>}

                    </div>
                </Container>
            </div>
        )

}

export default TopSellers;